@import "src/styles/params.module";

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.form {
  width: 30%;
  box-sizing: border-box;
  padding: 20px;
  @include _1400 {
    width: 40%;
  }
  @include _1200 {
    width: 50%;
  }
  @include _992 {
    width: 60%;
  }
  @include _768 {
    width: 90%;
  }
  @include _576 {
    width: 98%;
  }
}
